import { useEffect, useState } from 'react'
import axios from 'axios'
import React, { useRef } from 'react';
import { toPng } from 'html-to-image';
//COMPONENTES PARTES
import IcoStatus from '../partils/IcoStatus'
import { Link, useParams } from 'react-router-dom'
import TenancyColor from '../midllers/TenancyColor'
import ImgBgAreaDoCliente from '../../acets/img_bkg/bgareacliente.png'
import iconDownload from'../../acets/img_icon/iconDownload.svg'
//INPORTAÇOES DE ICONES E IMAGEN

export default function Areadocliente(){
    const divRef = useRef();//converter div para imagem
    const [data,setData]=useState([])
    const [showFoto,setShowFoto]=useState(false)
    const { id, ch } = useParams()
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Area do cliente',
        mainstring:'nome',
        url:'/api/areadocliente/',
        url2:'',
        search:'/api/cliente/search/',
        urlOptions1:'',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
       
    }
    //FUNÇÕES=====================================
    
    function rec() {
      // CARGAR ESTADOS Y ACTUALIZAR CICLO DE COMPONENTES
      axios.get(config.url + id + "/" + ch)
        .then(resp => {
          axios.get(config.url + id + "/" + ch, {
            headers: {
              id_tenancy: resp.data[0].tenancyId, // Correção da sintaxe dos headers
              nome:resp.data[0].orcamento.cliente.nome,
              device: navigator.userAgent // Captura o dispositivo/navegador do usuário
            }
          })
          .then(resp => {
            setData(resp.data);
          })
          .catch(error => {
            console.error('Erro ao carregar os dados da segunda chamada:', error);
          });
        })
        .catch(error => {
          console.error('Erro ao carregar os dados:', error);
        });
      }
      //funcao para cirar imagem da div react-to-image
      const handleDownloadImage = () => {
        if (divRef.current === null) {
          return;
        }
    
        toPng(divRef.current)
          .then((dataUrl) => {
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'div-image.png'; // Nome do arquivo
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
      };
    const finalRender = data.map((respLoop) => {
      
      return (
        <div className='row between w100 ma5 bbot1' key={respLoop.id_pivo_orcamento_produto}>
          <label className='w10 '>{respLoop.quantidade}</label>
          <label className='w50 '>{respLoop.produto_servico.descricao}</label>
          <label className='w15 '><span className='fs06em'>R$:</span>{respLoop.preco}</label>
          <label className='w20 fim'><span className='fs06em'>R$:</span>{respLoop.quantidade*respLoop.preco}</label>
        </div>
      );
    });
      
  
      
    // Función para calcular el total por ítem
function calcularTotalPorItem(item) {
  return parseFloat(item.quantidade) * parseFloat(item.preco.replace(',', '.'));
}

// Función para calcular la suma total de todos los ítems
function calcularSumaTotal(data) {
  let sumaTotal = 0;
  data.forEach(item => {
      sumaTotal += calcularTotalPorItem(item);
  });
  return sumaTotal;
}

// Imprimir la suma total de todos los ítems
const sumaTotal = calcularSumaTotal(data);
//console.log(`La suma total de todos los ítems es: ${sumaTotal.toFixed(2)}`);


  const DataOrcamento = data[0]&&data[0].createdAt;
  const TenancyDoPedido = data[0]&&data[0].tenancyId;
    console.log(data[0]);
    return(
      <div className='column center w100 b7' style={{backgroundImage: `url(${ImgBgAreaDoCliente})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
        <div className='w40 h100 column between center cell-tamanho'>

            
           
           <div className='h10 column center'>
              <a href='https://autometricas.com.br'>
                <label className='c2'>
                  Auto Métricas - soluções digitais.
                </label>
              </a>
            </div>
           
            
            <div ref={divRef} className='w90 h20 center column b2 cont-circ7 borda1'>
            <h5 className='c1 w90 fs08em'>Nota de serviço digital Nº{id}</h5>
            <div className='w90 row between'>
              <h5 className='c1 fs1p1em'>{TenancyDoPedido}</h5>
              <div className='h5 column center '>
                {
                  (() => {
                    switch(data[0] && data[0].orcamento.status) {
                      case 1: 
                        return <div className="fb b2 cont-circ7 borda1 fs08em p5 h4 column center c3" style={{background:'#68A652'}}>Em andamento</div>
                      case 2: 
                        return <div className="fb b2 cont-circ7 borda1 fs08em p5 h4 column center c1" style={{background:'#FFEC87'}}>Pgto/em aberto</div>
                      case 3: 
                        return <div className="fb b2 cont-circ7 borda1 fs08em p5 h4 column center c1" style={{background:'#B1FF95'}}>Finalizado Pago</div>
                      case 4: 
                        return <div className="fb b2 cont-circ7 borda1 fs08em p5 h4 column center c1" style={{background:'#FFA487'}}>Cancelado</div>
                      default:
                        return null;
                    }
                  })()
                }
              </div>
              </div>
            <div className='w90 row between borda1 ma10b0'>
              <h5 className='c1 pcb7 fs1p1em fw700'>{data[0]&&data[0].orcamento.cliente.nome}</h5>
              <div className='column center w30'>
                <label>Data</label>
                <h6 className='c1 pcb7 fs06em'>{DataOrcamento&&DataOrcamento.substring(0, 10)}</h6>
              </div>
              
            </div>
            <div className='w90 row between borda1'>
              <label className='pcb7  w50'>{data[0]&&data[0].orcamento.cliente.carro}</label>
              <label className='pcb7  w45'>{data[0]&&data[0].orcamento.cliente.placa}</label>
            </div>
            
                <div className='w90 row between ma10b0'>
                    <label className='w10 borda1 pcb7'>Qnt</label>
                    <label className='w50 borda1 pcb7'>Descrição</label>
                    <label className='w15 borda1 pcb7'>V.Unit</label>
                    <label className='w20 fim borda1 pcb7'>V.Total</label>
                </div>
                <div className='w90 ma10b0'>
                    {
                     finalRender
                    }
                </div>
                <div className='w100 column'>
                <div className='w90 row between'>
                
                    <h5 className='c1'>Total:</h5>
                    {
                      <h5 className='c1'>R$:{sumaTotal}</h5>
                    }
                </div>
              </div>
              
            </div>
            {data[0] && data[0].orcamento.observacao !=='null'&&data[0] && data[0].orcamento.observacao&& 
              <div className='w90 h10 column center b2 cont-circ7 ma25b0'>
                <h5 className='w90'>Comentário:</h5>
                <label className='w90 ma10b0'>{data[0].orcamento.observacao}</label>
              </div>
            }


            {(data[0] && (data[0].orcamento.foto_antes || data[0].orcamento.foto_depois))&&
            <div className='w90 h10 column center b2 cont-circ7 ma25b0'>
              <div className='w90 h10 b2 row between ma10b0'>
                <h5 className='w90 pp' onClick={()=>setShowFoto(!showFoto)}>Fotos do serviço:<span>(clique aqui)</span></h5>
                {data[0]&&data[0].orcamento.foto_antes&&showFoto&&
                <div className='w40 cell-tamanho'>
                  <label>Foto Antes</label>
                  <img src={axios.defaults.baseURL+'/'+data[0].orcamento.foto_antes} className='w100'/>
                </div>}
               {data[0]&&data[0].orcamento.foto_depois&&showFoto&&
                <div className='w40 cell-tamanho'>
                  <label>Foto Depois</label>
                  <img src={axios.defaults.baseURL+'/'+data[0].orcamento.foto_depois} className='w100'/>
              </div>}
              </div>
            </div>
            }
           
           <button onClick={handleDownloadImage}><img src={iconDownload}/>Baixar nota como imagem</button>
           <label>Tm 2024</label>
           {/*
           <div className='w100 h7 b31 row center'>
              <div className='w80 row between'>
                <label>Home</label>
                <label>Agenda</label>
                <label>Historico</label>
              </div>
            </div>
*/}
        </div>
    </div>
    )
}

