import axios from 'axios'
import { useState, useMemo } from 'react'
import { Navigate } from 'react-router';
import Menu from '../partils/Menu'
import LogoCardapio360 from '../../acets/img_icon/logo192.svg'
import TenancyColor from '../midllers/TenancyColor';


export default function Login(){
  const config = useMemo(() => ({
      url:'api/auth/'
  }), []);
  
    const tenancylocal = localStorage.getItem('ClienteTenancy')
    const [sucesse,setSucess]=useState(false)
    const [status, setStatus]=useState("")
    const initialValues={
        email:'',
        senha:''
    }
    const [values,setValues]=useState(initialValues);
    
    function onSubmit(ev) {
        ev.preventDefault();
        //console.log("clicado")
        if (values.email.trim() === '' || values.senha.trim() === '') {
          alert('ATENÇÃO: Preencha o campo Email e/ou Senha!');
      }
        else{
        axios.post(config.url, values).then(res=>{
          //console.log(res)
            if(res.data?.ERRO==="Nome ou senha incorreto(s)"){
                alert("Usuario ou senha incorretos")
                setStatus("Senha errada")
            }
            else{
                if(res.data.token){
                    localStorage.setItem('keyItemNameg', res.data.token);
                    //localStorage.setItem('keyItemNameg', res.data.token)
                    const convert = JSON.stringify(res.data.a); 
                    localStorage.setItem('ItemUsers',convert )  
                    localStorage.setItem('ClienteTenancy',res.data.a.tenancyId) 
                    //console.log(res.data.a)
                    localStorage.setItem('localcolor1',res.data.a.color1)
                    setStatus("Carregando...")
                }
                //console.log(res.data.posts)
                setSucess(true)
            }
            //console.log(res.data)
        })}
    }
    function onchenge(ev) {
        const {name,value} = ev.target;
        setValues({ ...values, [name]:value });//SETANDO O VALUE EM NAME
    }



  return(
    <div className='w100 column center site-header-img-login h100'>
      {sucesse&&<Navigate to="/painel" />}
      
      <section className='h50 w40 cell-tamanho90 column around b-vidro cont-circ8'>
      <h5 className='fb w90 c1'>Login</h5>
          <div className=' h40 w90 cont-circ8  column center '>
            <label className='fs1p3em ma10b0 w100 c1'>{tenancylocal}</label> 
            <label className='fs08em c1 w100 ma25b0'>Login apenas para empresas ;D</label>
            <div className="w100">
              <form onSubmit={onSubmit}>
                <label className="w100 c1 fb">Email:</label>
                <input type="text" name="email"  onChange={onchenge} placeholder="Ex:joao@gmail.com" style={{color:'#000'}}></input>
                <label className="w100 c1 fb">Senha:</label>
                <input type="password" name="senha" onChange={onchenge} placeholder="*****" style={{color:'#000'}}></input>
                <button className='w100 column center h5 ma10b0 pp cont-circ15' type="submit" style={{backgroundColor: TenancyColor().backgroundColor}}>Login</button>
              </form>
            </div>
          </div>
          <label className='fs08em ma10b0 w100 cen c1'>{tenancylocal} - Auto Metricas 2023</label> 
      </section>
      
      
      
    </div>
  )
}

/*
<a href="mailto:contato.cardapio360@gmail.com" className='fb h15 column center'>
        
          Solicitar Conta(Empresa)
        
      </a> 

*/