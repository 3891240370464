
import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES

//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'


import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'

import TenancyColor from '../midllers/TenancyColor'
import { useNavigate } from 'react-router-dom';
import AdminHeader from '../partils/AdminHeader'

export default function AdminVendaRealizada(){
    const navigate = useNavigate();
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Faturamento diario',
        mainstring:'nome',
        url:'/api/pivo_orcamento_produto/',
        url2:'',
        search:'/api/pivo_orcamento_produto/search/',
        urlOptions1:'',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    //id_cliente	nome	placa	carro	endereco	telefone	email	status	observacao	codigo_acess	tenancyId
    //id_cliente: '',	nome: '',	placa: '',	carro: '',	endereco: '',	telefone: '',	email: '',	status: '',	observacao: '',	codigo_acess: '',	tenancyId: '',
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
      id_cliente: '',	nome: '',	placa: '',	carro: '',	endereco: '',	telefone: '',	email: '',	status: '',	observacao: '',	codigo_acess: ''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    const[showDetalhes,setShowDetalhes]=useState(false)
    //FUNÇÕES=====================================
    const tlocal = localStorage.getItem('keyItemNameg')
    const headers = {
        'Content-Type': 'application/json',
        'token':tlocal,
    };
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(`${config.url}findall/listarvendasrealizadas`,{headers}).then(resp=>{
            //console.log(resp.data)
            const invalid = {ERRO: 'token invalido 3'};
            if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
              navigate('/login');
              window.location.reload(true);
            }
            else{

              setData(resp.data);
              //console.log(data)
            }
        })
       
        
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
        ev.preventDefault();
        if(values.nome===''){
          alert("Atencao, preencha todos os campos!")
           }else{
            axios[values.id_cliente?'put':'post'](config.url,values,{headers}).then(()=>{
                setOpenNew(false)
                setValues(initialValues);
                rec();
            })
          }
    }
    const editar = function(id){//EDITA VER CARREGA OS DADOS DO ITEM A SER EDITADO
        setOpenNew(true)
        axios.get(config.url + id,{headers}).then(respp=>{
            setValues(respp.data[0])
            })
    }
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id,{headers}).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values,{headers}).then(respp=>{
            setData(respp.data)       
        })
       
    }
    //console.log(data[0].ERRO==='token invalido 3')
    //COMPONENTES DE LOOP==============================
    const groupedData = data.reduce((acc, item) => {
        const date = item.createdAt.slice(0, 10);
        
        if (!acc[date]) {
            acc[date] = [];
        }
    
        acc[date].push(item);
        return acc;
    }, {});
    
    const final = Object.keys(groupedData).map(date => {
        const items = groupedData[date];
        // tudo o que entrou incluindo custos e fornecedores externos
        const totalfaturamento = items.reduce((sum, item) => sum + (item.quantidade * item.preco), 0);
        // tudo o que entrou que pertence somente a oficina
        const totalOficina = items
            .filter(item => item.cliente_fornecedor.status === 2)  // Filtra os itens com status = 2
            .reduce((sum, item) => sum + (item.quantidade * item.preco), 0);  // Calcula o total
       
        // tudo o que entrou que pertence aos fornecedores
        const totalfornecedores = items
            .filter(item => item.cliente_fornecedor.status === 1)  // Filtra os itens com status = 2
            .reduce((sum, item) => sum + (item.quantidade * item.preco), 0);  // Calcula o total
        // tudo o que entrou para a oficina tirando os custos 
        const totalLucroOficina = items
        .filter(item => item.cliente_fornecedor.status === 2)  // Filtra os itens com status = 2
        .reduce((sum, item) => sum + (item.quantidade * item.preco - item.quantidade * item.produto_servico.custo), 0);
        //const total de mao de obra da oficina e fornecedores
        const totalmaodeobra = items
            //.filter(item => item.cliente_fornecedor.status === 2)  // Filtra os itens com status = 2
            .filter(item => item.produto_servico.tipo === "1")  // Filtra os itens com status = 2
            .reduce((sum, item) => sum + (item.quantidade * item.preco), 0);  // Calcula o tota
        //const total de mao de obra da oficina e fornecedores
        const totalmaodeobraoficina = items
            .filter(item => item.cliente_fornecedor.status === 2)  // Filtra os itens com status = 2
            .filter(item => item.produto_servico.tipo === "1")  // Filtra os itens com status = 2
            .reduce((sum, item) => sum + (item.quantidade * item.preco), 0);  // Calcula o tota
        //const total de produtos da oficina e fornecedores
        const totalprodutos = items
            .filter(item => item.produto_servico.tipo === "2")  // Filtra os itens com status = 2
            .reduce((sum, item) => sum + (item.quantidade * item.preco), 0);  // Calcula o tota
            //const total de produtos da oficina e fornecedores
        const totalprodutosoficina = items
        .filter(item => item.cliente_fornecedor.status === 2)  // Filtra os itens com status = 2
        .filter(item => item.produto_servico.tipo === "2")  // Filtra os itens com status = 2
        .reduce((sum, item) => sum + (item.quantidade * item.preco), 0);  // Calcula o tota
        // tudo o que entrou para a oficina tirando os custos 
        const totalLucroProdutoOficina = items
        .filter(item => item.cliente_fornecedor.status === 2)  // Filtra os itens com status = 2
        .filter(item => item.produto_servico.tipo === "2")  // Filtra os itens com status = 2
        .reduce((sum, item) => sum + (item.quantidade * item.preco - item.quantidade * item.produto_servico.custo), 0);

        
        return (
            <div key={date} className="w100 h4 row ma5 center sombra-small b2">
                <div className='w90 h4 column'>
                    <div className='w100 fb ma10b0 fs1p3em'>{date}</div>
                    {items.map(namesString => (
                        <div key={namesString.id_pivo_orcamento_produto} className="w100 h4 row between linha">
                            <div className='w10 c4  column center cell-tamanho15'
                            style={{ background: 
                                    namesString.orcamento.status === 1
                                    ? "#68A652"
                                    : namesString.orcamento.status === 2
                                    ? "#FFEC87"
                                    : namesString.orcamento.status === 3
                                    ? "#B1FF95"
                                    : namesString.orcamento.status === 4
                                    ? "#FFA487"
                                    : "transparent"
                                }}> 
                                <label className='fs06em fb pcb7'>{
                                    namesString.orcamento.status === 1
                                    ? "Em serv."
                                    : namesString.orcamento.status === 2
                                    ? "Em aber."
                                    : namesString.orcamento.status === 3
                                    ? "Pago"
                                    : namesString.orcamento.status === 4
                                    ? "Cancela.":"sem dados"

                                }
                                </label>
                            </div>
                            <label className='fs06em fb pcb7 b13 w10 column center cell-tamanho15 c3 cen'>{namesString.cliente_fornecedor.cliente.nome}</label>
                            <div className='w40 cell-tamanho20 cell-margin5 c4 cell cell-text-smile08'>{namesString.produto_servico.descricao}</div>
                            <div className='w10 cell-texto-p c4 cell-tamanho15'>
                                Lucro: {namesString.quantidade * namesString.preco - namesString.quantidade * namesString.produto_servico.custo}
                            </div>
                            <div className='w10 cell-texto-p c4 cell-tamanho15'> {namesString.quantidade} x {namesString.preco}</div>
                            <div className="w10 h4 row end cell-tamanho15 fs08em ">
                                Total R$:{namesString.quantidade * namesString.preco}
                            </div>
                        </div>
                    ))}
                    
                    <div className="w100 h4 row between ma10b0 cell-tamanho">   
                        <div className='w10 cell-texto-p c4 cell-tamanho cen fs06em row between cell-linha'>Faturamento do Dia R$:<span className='fb c4 cen w100 cell-tamanho20'>{totalfaturamento}</span></div>
                        {showDetalhes?
                        <>
                            <div className='w10 cell-texto-p c4 cell-tamanho cen fs06em row between cell-linha'>Faturamento da oficina no Dia: R$<span className='fb c4 cen w100 cell-tamanho20'>{totalOficina}</span></div>
                            <div className='w10 cell-texto-p c4 cell-tamanho cen fs06em row between cell-linha'>Fernecedores externos do Dia: R$<span className='fb c4 cen w100 cell-tamanho20'>{totalfornecedores}</span></div>
                            <div className='w10 cell-texto-p c4 cell-tamanho cen fs06em row between cell-linha'>Lucro da oficina no dia: R$<span className='fb c4 cen w100 cell-tamanho20'>{totalLucroOficina}</span></div>
                            <div className='w10 cell-texto-p c4 cell-tamanho cen fs06em row between cell-linha'>Mão de obra no dia: R$<span className='fb c4 cen w100 cell-tamanho20'>{totalmaodeobra}</span></div>
                            <div className='w10 cell-texto-p c4 cell-tamanho cen fs06em row between cell-linha'>Mão de obra da oficina no dia: R$<span className='fb c4 cen w100 cell-tamanho20'>{totalmaodeobraoficina}</span></div>
                            <div className='w10 cell-texto-p c4 cell-tamanho cen fs06em row between cell-linha'>Faturamento de produtos no dia: R$<span className='fb c4 cen w100 cell-tamanho20'>{totalprodutos}</span></div>
                            <div className='w10 cell-texto-p c4 cell-tamanho cen fs06em row between cell-linha'>Faturamento de produtos da oficina: R$<span className='fb c4 cen w100 cell-tamanho20'>{totalprodutosoficina}</span></div>
                            <div className='w10 cell-texto-p c4 cell-tamanho cen fs06em row between cell-linha'>Lucro de produtos da oficina no dia: R$<span className='fb c4 cen w100 cell-tamanho20'>{totalLucroProdutoOficina}</span></div>
                            <label className='fb w10 cen pp c4 fs06em pcb10 b23 cell-tamanho' onClick={()=>setShowDetalhes(!showDetalhes)}>Menos dados</label>
                        </>:<label className='fb w10 cen pp c4 fs06em pcb10 b23 cell-tamanho' onClick={()=>setShowDetalhes(!showDetalhes)}>Mais dados</label>
                        }
                    </div>
                </div>
            </div>
        );
    });

      //id_cliente	nome	placa	carro	endereco	telefone	email	status	observacao	codigo_acess	tenancyId
    return(
        <>
        {openNew&&           
            <div className='w100 h100 posifixedinicio column center z17' >
                <div className='w100 h100 posifixedinicio fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90'>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.id_cliente?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off'>
                            <div className='ma5'>
                                <label className='c4 fs09em'>Campos obrigatórios <span className='c9'>*</span> </label>
                            </div>
                            <div>
                                <label>Nome <span className='c9'>*</span></label>
                                <input type="text"  name="nome"  onChange={onChange} value={values.nome} autoComplete='off'></input>
                            </div>
                            <div>
                                <label>Endereco:</label>
                                <input type="text"  name="endereco"  onChange={onChange} value={values.endereco}>
                                </input>
                            </div>
                            <div className='row between w100'>
                                <div className='w30'>
                                    <label>Placa <span className='c9'>*</span></label>
                                    <input type="text"  name="placa"  onChange={onChange} value={values.placa}></input>
                                </div>
                                <div className='w30'>
                                    <label>Carro <span className='c9'>*</span></label>
                                    <input type="text"  name="carro"  onChange={onChange} value={values.carro}></input>
                                </div>
                                <div className='w30'>
                                    <label>Telefone:</label>
                                    <input type="number"  name="telefone"  onChange={onChange} value={values.telefone}></input>
                                </div>
                            </div>
                            <div className='row between w100'>
                            
                                <div className='w100'>
                                    <label>Email:</label>
                                    <input type="text"  name="email"  onChange={onChange} value={values.email}></input>
                                </div>
                                
                            </div>
                            <div>
                                <label>Observacao:</label>
                                <input type="text"  name="observacao"  onChange={onChange} value={values.observacao}></input>
                            </div>
                           
                            <div className='cell-margin5'>
                            <button type="submit" className='w100'>{values.id_cliente ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </form>
                        <div className='w100 row between'>
                            <label className='w40'>Sem mais opções</label>
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w40 ma10b0 btn05">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
        
        <section className='h100 row between b31'>
            
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center  celular">
                    
                <div className="w90 row between cell-tamanho celular">
                    <div className="h100 w100 cont-circ3 column  cell-tamanho cell-paddingcb10">
                        <h5 className='w90'>{config.pageName}</h5>
                        {/*<form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            
                            <div className='w80'>
                                <input type="text" name="nome" onChange={onChange} placeholder=' Search'/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>*/}
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                             {final}
                        </div>     
                    </div>
                </div>
            </div>
            
        </section>
      </>
    )
}





